body {
	& .react-select__control {
		border: 1px solid var(--#{$prefix}border-color);
		background: none;
		border-radius: $border-radius;
		padding: 0.375rem 0.75rem;
		min-height: rem(35px);
		
		&:hover {
			border-color: var(--#{$prefix}border-color);
		}
		&.react-select__control--is-focused {
			box-shadow: $input-btn-focus-box-shadow;
			border-color: var(--#{$prefix}border-color);
		}
		& .react-select__value-container {
			padding: 0;
			
			& .react-select__placeholder {
				margin: 0;
				color: rgba(var(--#{$prefix}inverse-rgb), .5);
			}
			& .react-select__single-value {
				color: var(--#{$prefix}inverse);
			}
			& .react-select__input-container {
				margin: 0;
				padding: 0;
				
				& .react-select__input {
					color: var(--#{$prefix}inverse);
				}
			}
			&.react-select__value-container--is-multi {
				margin-bottom: rem(-5px);
				
				& .react-select__placeholder,
				& .react-select__input-container {
					margin-top: rem(-5px);
				}
				
				& .react-select__multi-value {
					margin: 0 rem(4px) rem(4px) 0;
					background: var(--#{$prefix}theme);
					
					& .react-select__multi-value__label {
						color: var(--#{$prefix}theme-color);
						font-size: rem(12px);
						padding: rem(2px);
						
						@if $enable-rtl {
							padding-right: rem(6px);
						} @else {
							padding-left: rem(6px);
						}
					}
					& .react-select__multi-value__remove {
						border-radius: 0;
						padding: 0 3px;
						
						&:hover {
							background: none;
							color: rgba(var(--#{$prefix}inverse-rgb), .5);
						}
					}
				}
			}
		}
		& .react-select__indicators {
			& .react-select__indicator {
				padding: 0;
				color: rgba(var(--#{$prefix}inverse-rgb), .5);
				
				&:hover {
					color: var(--#{$prefix}inverse);
				}
			}
			& .react-select__indicator-separator {
				background: none;
				margin: 0;
			}
		}
	}
	& .react-select__menu {
		background: $dropdown-bg;
		border-radius: 0;
		border: 1px solid $dropdown-border-color;
		
		& .react-select__menu-list {
			& .react-select__option {
				&.react-select__option--is-focused {
					background: $dropdown-link-hover-bg;
				}
				&.react-select__option--is-selected {
					background: $dropdown-link-active-bg;
					color: $dropdown-link-active-color;
				}
			}
		}
	}
}