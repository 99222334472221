.tooltip-toggle {
    position: relative;
}

.tooltip-toggle::before {
    content: attr(data-tooltip); /* Utiliza el atributo data-tooltip como contenido del tooltip */
    position: absolute;
    bottom: 100%; /* Posiciona el tooltip arriba del elemento padre */
    left: 50%; /* Centra el tooltip horizontalmente */
    transform: translateX(-50%); /* Ajusta la posición horizontal */
    background-color: rgba(0, 0, 0, 0.8); /* Color de fondo */
    color: #fff; /* Color del texto */
    padding: 5px 10px; /* Espaciado alrededor del texto */
    border-radius: 5px; /* Bordes redondeados */
    font-size: 12px; /* Tamaño del texto */
    white-space: nowrap; /* Evita el desbordamiento del texto */
    opacity: 0; /* Hace el tooltip invisible por defecto */
    transition: opacity 0.3s ease; /* Transición suave de la opacidad */
}

.tooltip-toggle:hover::before {
    opacity: 1; /* Hace el tooltip visible al hacer hover */
}


.tabla-scrollable {
    overflow-x: auto;
    max-width: 100%; /* Ajusta el ancho máximo según tu diseño */
  }
  
  .tabla-scrollable table {
    width: 100%; /* Para ocupar todo el ancho disponible */
    border-collapse: collapse; /* Para fusionar los bordes de las celdas */
  }
  
  .tabla-scrollable th, .tabla-scrollable td {
    padding: 8px; /* Espaciado interno de las celdas */
    text-align: left; /* Alineación del texto en las celdas */
  }