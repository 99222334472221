/* styles.css */
.totales-wrapper {
    width: 100%;
    float: center;
}

.totales-header {
    background-color: #323F4F;
    color: white;
    font-size: 12px;
}

.totales-row {
    font-size: 12px;
}

.totales-cell {
    text-align: right;
}

.table-derechohabientes {
    font-size: 10px !important; 
}